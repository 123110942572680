import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import { MenuItem } from '@mui/material';

const RightDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        margin: '0',
        width: '500px',
        transform: 'translateX(-50%)',
        left: '50%',
        // [theme.breakpoints.up('sm')]: {
        //   maxWidth: '600px',
        // },
      },
  }));

export default function LessonDialog(props) {
  const { open, onClose, register, handleSubmit, errors, onSubmit, watch, control, Controller, classes, fetchSubject, subjects } = props;
  return (
    <RightDialog open={open} onClose={() => onClose} fullScreen>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3 }}>
            <DialogTitle>{watch('id') ? 'Update' : 'Create'} Lesson</DialogTitle>
            <IconButton onClick={() => onClose()}><CancelIcon /></IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <Controller
                            name="ClassId"
                            control={control}
                            rules={{
                                required: 'Please select a class'
                            }}
                            render={({ field }) => (
                                <TextField
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        fetchSubject(e.target.value);
                                    }}
                                    fullWidth
                                    label="Class"
                                    variant="outlined"
                                    select
                                    error={!!errors.ClassId}
                                    helperText={errors.name && 'Please select a class'}
                                >
                                    {Array.isArray(classes) && classes.map(x => {
                                        return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                    })}   
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Subject"
                            name="SubjectId"
                            variant="outlined"
                            select
                            {...register('SubjectId', { required: true })}
                            error={!!errors.ClassId}
                            helperText={errors.name && 'Please select a subject'}
                        >
                            {Array.isArray(subjects) && subjects.map(x => {
                                return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                            })}   
                        </TextField>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            variant="outlined"
                            {...register('name', { required: true })}
                            error={!!errors.name}
                            helperText={errors.name && 'Please enter a valid name'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Overview"
                            name="overview"
                            variant="outlined"
                            multiline
                            rows={4}
                            {...register('overview', { required: true })}
                            error={!!errors._id}
                            helperText={errors._id && 'Please enter a valid details'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Total content"
                            name="count"
                            variant="outlined"
                            {...register('count', { required: true })}
                            error={!!errors.count}
                            helperText={errors.count && 'Please enter a valid number'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Order Number"
                            name="orderNumber"
                            variant="outlined"
                            {...register('orderNumber', { required: true })}
                            error={!!errors.orderNumber}
                            helperText={errors.orderNumber && 'Please enter a valid order number'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Controller
                            name="image"
                            control={control}
                            render={({ field }) => (
                                <input
                                type="file"
                                onChange={(e) => field.onChange(e.target.files[0])}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type='Submit'>{watch('id') ? 'Update' : 'Create' }</Button>
            </DialogActions>
        </form>
    </RightDialog>
  );
}