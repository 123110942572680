
import react, { useState, useEffect } from 'react';
import { 
  Box,
  Typography,
  Button,
  TextField,
  MenuItem
} from '@mui/material';
import TablePage from './component/table.component';
import VideoDialog from './component/videoDialog.component';
import { useForm, Controller } from 'react-hook-form';

export function VideoPage(props) {
  const { 
    addVideoContent,
    fetchVideoContent,
    fetchLesson,
    videoContentUpdate,
    isLoading,
    isSaved,
    videos,
    lessons,
    fetchClass,
    classes,
    fetchSubject,
    subjects
  } = props;

  const { register, handleSubmit, control, setValue, watch, reset, getValues, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false);
  const [_class, setClass] = useState(null);
  const [subject, setSubject] = useState(null);
  const [lesson, setLesson] = useState(null);

  useEffect(() => {
    if(classes.length === 0) {
      fetchClass();
    }
    fetchVideoContent(null, null, null);
  }, []);

  useEffect(() => {
    if(isSaved) {
      setOpen(false);
      reset();
      fetchVideoContent(_class, subject, lesson);
    }
  }, [isSaved]);

  const setRowValues = (data) => {
    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  }

  const onSubmit = (data) => {
    if(data.id){
      videoContentUpdate(data);
    } else {
      addVideoContent(data);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h1">Videos</Typography>
        <Box>
          <TextField
            variant="outlined"
            select
            label="Select Class"
            sx={{ width: "200px", mr: 2 }}
            size="small"
            value={_class}
            onChange={e => {
              setClass(e.target.value);
              fetchSubject(e.target.value);
            }}
          >
            {classes.map(x => {
              return (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
            })}
          </TextField>
          <TextField
            variant="outlined"
            select
            label="Select Subject"
            sx={{ width: "200px", mr: 2 }}
            size="small"
            value={subject}
            onChange={e => {
              setSubject(e.target.value);
              fetchLesson(e.target.value);
            }}
          >
            {subjects.map(x => {
              return (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
            })}
          </TextField>
          <TextField
            variant="outlined"
            select
            label="Select Lesson"
            sx={{ width: "200px", mr: 2 }}
            size="small"
            value={lesson}
            onChange={e => {
              setLesson(e.target.value);
              fetchVideoContent(_class, subject, e.target.value);
            }}
          >
            {lessons.map(x => {
              return (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
            })}
          </TextField>
          <Button onClick={() => setOpen(true)} variant='contained'>Add video</Button>
        </Box>
      </Box>
      <TablePage
        rows={videos}
        setOpen={setOpen}
        videoContentUpdate={videoContentUpdate}
        setRowValues={setRowValues}
      />
      <VideoDialog
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        watch={watch}
        control={control}
        Controller={Controller}
        classes={classes}
        fetchSubject={fetchSubject}
        subjects={subjects}
        fetchLesson={fetchLesson}
        lessons={lessons}
        getValues={getValues}
      />
    </Box>
  );
}