import * as actionTypes from "./classActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
const { REACT_APP_API_URL } = process.env;

export const registerClass = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
        const params = new FormData();
            Object.keys(data).forEach(key => {
            const value = data[key];
            params.append(key, value);
        });
        dispatch(registerClassInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/class/store`, params, config))
        .then((response) => {
            dispatch(registerClassSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(registerClassFailure());
            checkError(error);
        })
    };
};

export const registerClassInit = () => ({
    type: actionTypes.REGISTER_CLASS
});

export const registerClassFailure = () => ({
    type: actionTypes.REGISTER_CLASS_FAILURE
});

export const registerClassSuccess = data => ({
    type: actionTypes.REGISTER_CLASS_SUCCESS
});

export const classUpdate = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
        const params = new FormData();
            Object.keys(data).forEach(key => {
            const value = data[key];
            params.append(key, value);
        });
        dispatch(classUpdateInit());
        apiTimeout(axios.put(`${REACT_APP_API_URL}/class/${data.id}`, params, config))
        .then((response) => {
            dispatch(classUpdateSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(classUpdateFailure());
            checkError(error);
        })
    };
};

export const classUpdateInit = () => ({
    type: actionTypes.CLASS_UPDATE
});

export const classUpdateFailure = () => ({
    type: actionTypes.CLASS_UPDATE_FAILURE
});

export const classUpdateSuccess = data => ({
    type: actionTypes.CLASS_UPDATE_SUCCESS
});

export const fetchClass = () => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
    dispatch(fetchClassInit());
    apiTimeout(axios.get(`${REACT_APP_API_URL}/class`, config))
    .then((response) => {
        dispatch(fetchClassSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(fetchClassFailure());
        checkError(error);
    })
    };
};

export const fetchClassInit = () => ({
    type: actionTypes.FETCH_CLASS
});

export const fetchClassFailure = () => ({
    type: actionTypes.FETCH_CLASS_FAILURE
});

export const fetchClassSuccess = data => ({
    type: actionTypes.FETCH_CLASS_SUCCESS,
    payload: { data }
});