import react, { useState, useEffect } from 'react';
import { 
  Box,
  Typography,
  Button
} from '@mui/material';
import TablePage from './component/table.component';
import ClassDialog from './component/classDialog.component';
import { useForm, Controller } from 'react-hook-form';

export function ClassPage(props) {
  const { 
    registerClass,
    fetchClass,
    classUpdate,
    isLoading,
    isSaved,
    classes
  } = props;

  const { register, handleSubmit, control, setValue, watch, reset, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    fetchClass();
  }, []);

  useEffect(() => {
    if(isSaved) {
      fetchClass();
      setOpen(false);
      reset();
    }
  }, [isSaved]);

  const setRowValues = (data) => {
    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  }

  const onSubmit = (data) => {
    if(data.id){
      classUpdate(data);
    } else {
      registerClass(data);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h1">Class</Typography>
        <Button onClick={() => setOpen(true)} variant='contained'>Create Class</Button>
      </Box>
      <TablePage
        rows={classes}
        setOpen={setOpen}
        classUpdate={classUpdate}
        setRowValues={setRowValues}
      />
      <ClassDialog
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        watch={watch}
        control={control}
        Controller={Controller}
      />
    </Box>
  );
}