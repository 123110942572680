import { DashboardPage } from './dashboard.component';
import { connect } from 'react-redux';
// import { companyDashboard } from './../../api/company/companyAction';

const mapDispatchToProps = {
    // companyDashboard
};

const mapStateToProps = state => ({
    isLoading: state.adminPage.isLoading,
});

export const DashboardContainer = connect(mapStateToProps, mapDispatchToProps)(DashboardPage);