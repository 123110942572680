import { SubjectPage } from './subject.component';
import { connect } from 'react-redux';
import { addSubject, fetchSubject, subjectUpdate } from './../../api/subject/subjectAction';
import { fetchClass } from './../../api/class/classAction';
 
const mapDispatchToProps = {
    addSubject,
    fetchSubject,
    subjectUpdate,
    fetchClass
};

const mapStateToProps = state => ({
    isLoading: state.subjectPage.isLoading,
    isSaved: state.subjectPage.isSaved,
    subjects: state.subjectPage.subjects,
    classes: state.classPage.classes
});

export const SubjectContainer = connect(mapStateToProps, mapDispatchToProps)(SubjectPage);