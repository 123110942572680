export const ADD_SUBJECT = "ADD_SUBJECT";
export const ADD_SUBJECT_FAILURE = "ADD_SUBJECT_FAILURE";
export const ADD_SUBJECT_SUCCESS = "ADD_SUBJECT_SUCCESS";

export const SUBJECT_UPDATE = "SUBJECT_UPDATE";
export const SUBJECT_UPDATE_FAILURE = "SUBJECT_UPDATE_FAILURE";
export const SUBJECT_UPDATE_SUCCESS = "SUBJECT_UPDATE_SUCCESS";

export const FETCH_SUBJECT = "FETCH_SUBJECT";
export const FETCH_SUBJECT_FAILURE = "FETCH_SUBJECT_FAILURE";
export const FETCH_SUBJECT_SUCCESS = "FETCH_SUBJECT_SUCCESS";
