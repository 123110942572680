import { combineReducers } from 'redux';
import { adminReducer as adminPage } from './../api/admin/adminReducer';
import { classReducer as classPage } from './../api/class/classReducer';
import { subjectReducer as subjectPage } from './../api/subject/subjectReducer';
import { lessonReducer as lessonPage } from './../api/lesson/lessonReducer';
import { videoContentReducer as videoContentPage } from './../api/videoContent/videoContentReducer';
import { textContentReducer as textContentPage } from './../api/textContent/textContentReducer';

const appReducer = combineReducers({
    adminPage,
    classPage,
    subjectPage,
    lessonPage,
    videoContentPage,
    textContentPage
});

export default appReducer;