export const ADD_TEXT_CONTENT = "ADD_TEXT_CONTENT";
export const ADD_TEXT_CONTENT_FAILURE = "ADD_TEXT_CONTENT_FAILURE";
export const ADD_TEXT_CONTENT_SUCCESS = "ADD_TEXT_CONTENT_SUCCESS";

export const TEXT_CONTENT_UPDATE = "TEXT_CONTENT_UPDATE";
export const TEXT_CONTENT_UPDATE_FAILURE = "TEXT_CONTENT_UPDATE_FAILURE";
export const TEXT_CONTENT_UPDATE_SUCCESS = "TEXT_CONTENT_UPDATE_SUCCESS";

export const FETCH_TEXT_CONTENT = "FETCH_TEXT_CONTENT";
export const FETCH_TEXT_CONTENT_FAILURE = "FETCH_TEXT_CONTENT_FAILURE";
export const FETCH_TEXT_CONTENT_SUCCESS = "FETCH_TEXT_CONTENT_SUCCESS";
