import * as actionTypes from "./videoContentActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
const { REACT_APP_API_URL } = process.env;

export const addVideoContent = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
    dispatch(addVideoContentInit());
    apiTimeout(axios.post(`${REACT_APP_API_URL}/video-content/store`, data, config))
    .then((response) => {
        dispatch(addVideoContentSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(addVideoContentFailure());
        checkError(error);
    })
    };
};

export const addVideoContentInit = () => ({
    type: actionTypes.ADD_VIDEO_CONTENT
});

export const addVideoContentFailure = () => ({
    type: actionTypes.ADD_VIDEO_CONTENT_FAILURE
});

export const addVideoContentSuccess = data => ({
    type: actionTypes.ADD_VIDEO_CONTENT_SUCCESS
});

export const videoContentUpdate = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
    dispatch(videoContentUpdateInit());
    apiTimeout(axios.put(`${REACT_APP_API_URL}/video-content/${data.id}`, data, config))
    .then((response) => {
        dispatch(videoContentUpdateSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(videoContentUpdateFailure());
        checkError(error);
    })
    };
};

export const videoContentUpdateInit = () => ({
    type: actionTypes.VIDEO_CONTENT_UPDATE
});

export const videoContentUpdateFailure = () => ({
    type: actionTypes.VIDEO_CONTENT_UPDATE_FAILURE
});

export const videoContentUpdateSuccess = data => ({
    type: actionTypes.VIDEO_CONTENT_UPDATE_SUCCESS
});

export const fetchVideoContent = (classId, subjectId, lessonId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
    dispatch(fetchVideoContentInit());
    apiTimeout(axios.get(`${REACT_APP_API_URL}/video-content?ClassId=${classId}&SubjectId=${subjectId}&LessonId=${lessonId}`, config))
    .then((response) => {
        dispatch(fetchVideoContentSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(fetchVideoContentFailure());
        checkError(error);
    })
    };
};

export const fetchVideoContentInit = () => ({
    type: actionTypes.FETCH_VIDEO_CONTENT
});

export const fetchVideoContentFailure = () => ({
    type: actionTypes.FETCH_VIDEO_CONTENT_FAILURE
});

export const fetchVideoContentSuccess = data => ({
    type: actionTypes.FETCH_VIDEO_CONTENT_SUCCESS,
    payload: { data }
});