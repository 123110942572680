import * as actionType from "./videoContentActionType";

const initialState = {
  isLoading: false,
  isSaved: false,
  videos: []
};

export const videoContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_VIDEO_CONTENT:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.ADD_VIDEO_CONTENT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.ADD_VIDEO_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.VIDEO_CONTENT_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.VIDEO_CONTENT_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.VIDEO_CONTENT_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.FETCH_VIDEO_CONTENT:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_VIDEO_CONTENT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_VIDEO_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                videos: action.payload ? action.payload.data : [],
            };
        default:
            return state;
    }
}