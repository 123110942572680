import react, { useState, useEffect } from 'react';
import { 
  Box,
  Typography,
  Button,
  TextField,
  MenuItem
} from '@mui/material';
import TablePage from './component/table.component';
import LessonDialog from './component/lessonDialog.component';
import VideoDialog from './component/videoDialog.component';
import { useForm, Controller } from 'react-hook-form';

export function LessonPage(props) {
  const { 
    addLesson,
    fetchLesson,
    lessonUpdate,
    isLoading,
    isSaved,
    lessons,
    fetchClass,
    classes,
    fetchSubject,
    subjects,
    addVideoContent,
    videoIsSaved
  } = props;

  const { register, handleSubmit, control, setValue, watch, reset, formState: { errors } } = useForm();
  const form1 = useForm();
  const [open, setOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [_class, setClass] = useState(1);
  const [subject, setSubject] = useState(null);

  useEffect(() => {
    if(classes.length === 0) {
      fetchClass();
    }
    fetchLesson(null);
  }, []);

  useEffect(() => {
    if(isSaved) {
      setOpen(false);
      reset();
      fetchLesson(subject);
    }
  }, [isSaved]);

  useEffect(() => {
    if(videoIsSaved) {
      setVideoOpen(false);
      form1.reset();
    }
  }, [videoIsSaved]);

  const setRowValues = (data) => {
    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  }

  const onSubmit = (data) => {
    if(data.id){
      lessonUpdate(data);
    } else {
      console.log("data: ", data);
      addLesson(data);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h1">Lessons</Typography>
        <Box>
          <TextField
            variant="outlined"
            select
            label="Select Class"
            sx={{ width: "200px", mr: 2 }}
            size="small"
            value={_class}
            onChange={e => {
              setClass(e.target.value);
              fetchSubject(e.target.value);
            }}
          >
            {classes.map(x => {
              return (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
            })}
          </TextField>
          <TextField
            variant="outlined"
            select
            label="Select Subject"
            sx={{ width: "200px", mr: 2 }}
            size="small"
            value={subject}
            onChange={e => {
              setSubject(e.target.value);
              fetchLesson(e.target.value);
            }}
          >
            {subjects.map(x => {
              return (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
            })}
          </TextField>
          <Button onClick={() => setOpen(true)} variant='contained'>Create lesson</Button>
        </Box>
      </Box>
      <TablePage
        rows={lessons}
        setOpen={setOpen}
        setVideoOpen={setVideoOpen}
        lessonUpdate={lessonUpdate}
        setRowValues={setRowValues}
        setValue={form1.setValue}
        fetchSubject={fetchSubject}
        fetchLesson={fetchLesson}
      />
      <LessonDialog
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        watch={watch}
        control={control}
        Controller={Controller}
        classes={classes}
        fetchSubject={fetchSubject}
        subjects={subjects}
      />
      <VideoDialog
        open={videoOpen}
        onClose={() => {
          setVideoOpen(false);
          form1.reset();
        }}
        register={form1.register}
        handleSubmit={form1.handleSubmit}
        errors={form1.formState.errors}
        addVideoContent={addVideoContent}
        watch={form1.watch}
        control={form1.control}
        Controller={Controller}
        classes={classes}
        fetchSubject={fetchSubject}
        subjects={subjects}
        fetchLesson={fetchLesson}
        lessons={lessons}
        getValues={form1.getValues}
      />
    </Box>
  );
}