import * as actionTypes from "./adminActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
const { REACT_APP_API_URL } = process.env;

export const adminLogin = (data) => {
      return (dispatch) => {
        dispatch(adminLoginInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/admin/authenticate`, data))
        .then((response) => {
            setLoginSession(response.data.data);
            dispatch(adminLoginSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(adminLoginFailure());
            checkError(error);
        })
    };
};

export const adminLoginInit = () => ({
    type: actionTypes.ADMIN_LOGIN
});

export const adminLoginFailure = () => ({
    type: actionTypes.ADMIN_LOGIN_FAILURE
});

export const adminLoginSuccess = data => ({
    type: actionTypes.ADMIN_LOGIN_SUCCESS,
    payload: { data }
});

//Helper Function
function setLoginSession(data) {
    sessionStorage.setItem("loggedAdmin", JSON.stringify(data));
    sessionStorage.setItem("adminToken", data.token);
}