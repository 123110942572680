import * as actionType from "./subjectActionType";

const initialState = {
  isLoading: false,
  isSaved: false,
  subjects: []
};

export const subjectReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_SUBJECT:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.ADD_SUBJECT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.ADD_SUBJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.SUBJECT_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.SUBJECT_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.SUBJECT_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.FETCH_SUBJECT:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_SUBJECT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_SUBJECT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                subjects: action.payload ? action.payload.data : [],
            };
        default:
            return state;
    }
}