import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Button,
  TextField,
  Checkbox,
  Grid,
  FormControlLabel,
  InputAdornment,
  FormGroup,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";

export const LoginPage = (props) => {
  const { adminLogin, isLoggedIn, isLoading } = props;
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if(isLoggedIn) {
        navigate("/dashboard");
    }
  }, [isLoggedIn]);

  return (
    <Grid container spacing={5}>
      <Grid item sm={12} md={6}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
          <img src={`${process.env.PUBLIC_URL}/images/logo.png`} width="200px"/>
        </Box>
      </Grid>
      <Grid item md={6} sm={12} sx={{ borderLeft: "1px solid #eaeaea" }}>
        <Box sx={{ maxWidth: "100%", px: 5, display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", height: "100vh" }}>
          <Typography variant="h1"> Sign in</Typography>
          <Box sx={{ mt: 4 }}>
            <form onSubmit={handleSubmit(adminLogin)}>
              <TextField
                fullWidth
                label="Email"
                name="username"
                variant="outlined"
                {...register('username', { required: true, pattern: /^\S+@\S+$/i })}
                error={!!errors.username}
                helperText={errors.username && 'Please enter a valid email address'}
              />

              <TextField
                sx={{ mt: 3 }}
                fullWidth
                label="Password"
                name="password"
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                {...register('password', { required: true, minLength: 8 })}
                error={!!errors.password}
                helperText={errors.password && 'Password must be at least 8 characters long'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <Grid item sm={12} md={12} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked />}
                    label=" Remember me"
                  />
                </FormGroup>
                <Typography>
                  Forgot Password ?
                </Typography>
              </Grid>
              <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column", mt: 3 }}>
                <Button
                  disabled={isLoading}
                  fullWidth
                  variant="contained"
                  size="large"
                  type="submit"
                  sx={{ borderRadius: "50px" }}
                >
                  <span type="submit"> {isLoading ? "Wait logging in..." : "Login"}</span>
                </Button>
              </Box>
            </form>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
