import { VideoPage } from './video.component';
import { connect } from 'react-redux';
import { fetchLesson } from './../../api/lesson/lessonAction';
import { fetchClass } from './../../api/class/classAction';
import { fetchSubject } from './../../api/subject/subjectAction';
import { addVideoContent, videoContentUpdate, fetchVideoContent } from './../../api/videoContent/videoContentAction';
 
const mapDispatchToProps = {
    videoContentUpdate,
    fetchLesson,
    fetchVideoContent,
    fetchClass,
    fetchSubject,
    addVideoContent
};

const mapStateToProps = state => ({
    isLoading: state.videoContentPage.isLoading,
    isSaved: state.videoContentPage.isSaved,
    videos: state.videoContentPage.videos,
    lessons: state.lessonPage.lessons,
    classes: state.classPage.classes,
    subjects: state.subjectPage.subjects,
});

export const VideoContainer = connect(mapStateToProps, mapDispatchToProps)(VideoPage);