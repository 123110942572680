import * as React from 'react';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@mui/material';

export default function TablePage(props) {
  const {rows, setRowValues, setOpen, classUpdate} = props;
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead sx={{ backgroundColor: (theme) => theme.palette.primary.main, color: (theme) => theme.palette.secondary.main }}>
          <TableRow>
            <TableCell sx={{ color: (theme) => theme.palette.secondary.main }}>Name</TableCell>
            <TableCell sx={{ color: (theme) => theme.palette.secondary.main }}>ID</TableCell>
            <TableCell sx={{ color: (theme) => theme.palette.secondary.main }}>Image</TableCell>
            <TableCell sx={{ color: (theme) => theme.palette.secondary.main }} align="center">Status</TableCell>
            <TableCell sx={{ color: (theme) => theme.palette.secondary.main }} align="center">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.isArray(rows) && rows.length > 0 ? rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row._id}</TableCell>
              <TableCell align="center"><img src={row.image} alt={row.name} width="70px"/></TableCell>
              <TableCell align="center">
                <Switch
                  checked={row.status == 1}
                  onChange={() => classUpdate({ ...row, status: row.status == 0 ? 1 : 0 })}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </TableCell>
              <TableCell align="center">
                <IconButton
                  aria-label="edit"
                  title="Edit"
                  onClick={() => {
                    setRowValues(row);
                    setOpen(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          )) : (
            <TableRow>
              <TableCell colSpan={7}>
                <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                  <Typography sx={{ color: "#f7bbbb", fontSize: "13px" }}>No data available in table</Typography>
                  <img src={`${process.env.PUBLIC_URL}/images/addnewitem.svg`} width="200px" alt="Add new item" />
                  <Typography sx={{ color: "#3c763d", fontSize: "13px", fontWeight: 700 }}>Add new record or search with different criteria</Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
}