import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import { MenuItem } from '@mui/material';

const RightDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        margin: '0',
        width: '500px',
        transform: 'translateX(-50%)',
        left: '50%',
        // [theme.breakpoints.up('sm')]: {
        //   maxWidth: '600px',
        // },
      },
  }));

export default function SubjectDialog(props) {
  const { open, onClose, register, handleSubmit, errors, onSubmit, watch, control, getValues, Controller, classes } = props;
  return (
    <RightDialog open={open} onClose={() => onClose} fullScreen>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3 }}>
            <DialogTitle>{watch('id') ? 'Update' : 'Create'} Subject</DialogTitle>
            <IconButton onClick={() => onClose()}><CancelIcon /></IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            select
                            label="Class"
                            name="ClassId"
                            variant="outlined"
                            {...register('ClassId', { required: true })}
                            defaultValue={getValues("ClassId")}
                            error={!!errors.ClassId}
                            helperText={errors.ClassId && 'Please select a class'}
                        >
                            {classes.map(x => {
                                return (<MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>)
                            })}
                        </TextField>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            variant="outlined"
                            {...register('name', { required: true })}
                            error={!!errors.name}
                            helperText={errors.name && 'Please enter a valid name'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Controller
                            name="image"
                            control={control}
                            render={({ field }) => (
                                <input
                                type="file"
                                onChange={(e) => field.onChange(e.target.files[0])}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type='Submit'>{watch('id') ? 'Update' : 'Create' }</Button>
            </DialogActions>
        </form>
    </RightDialog>
  );
}