import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ProtectedRoute } from './App/protected.route';
import { LoginContainer } from './views/login/login.container';
import { DashboardContainer } from './views/dashboard/dashboard.container';
import { ClassContainer } from './views/class/class.container';
import { SubjectContainer } from './views/subject/subject.container';
import { LessonContainer } from './views/lesson/lesson.container';
import { VideoContainer } from './views/video/video.container';

export const RoutePath = () => (
  <Routes>
    <Route path='/' element={<ProtectedRoute><DashboardContainer /></ProtectedRoute>} />
    <Route path='/dashboard' element={<ProtectedRoute><DashboardContainer /></ProtectedRoute>} />
    <Route path='/class' element={<ProtectedRoute><ClassContainer /></ProtectedRoute>} />
    <Route path='/subject' element={<ProtectedRoute><SubjectContainer /></ProtectedRoute>} />
    <Route path='/lesson' element={<ProtectedRoute><LessonContainer /></ProtectedRoute>} />
    <Route path='/videos' element={<ProtectedRoute><VideoContainer /></ProtectedRoute>} />
    <Route path='/login' element={<LoginContainer />} />
  </Routes>
);
