import * as actionType from "./lessonActionType";

const initialState = {
  isLoading: false,
  isSaved: false,
  lessons: []
};

export const lessonReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_LESSON:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.ADD_LESSON_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.ADD_LESSON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.LESSON_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.LESSON_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.LESSON_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.FETCH_LESSON:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_LESSON_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_LESSON_SUCCESS:
            return {
                ...state,
                isLoading: false,
                lessons: action.payload ? action.payload.data : [],
            };
        default:
            return state;
    }
}