export const ADD_LESSON = "ADD_LESSON";
export const ADD_LESSON_FAILURE = "ADD_LESSON_FAILURE";
export const ADD_LESSON_SUCCESS = "ADD_LESSON_SUCCESS";

export const LESSON_UPDATE = "LESSON_UPDATE";
export const LESSON_UPDATE_FAILURE = "LESSON_UPDATE_FAILURE";
export const LESSON_UPDATE_SUCCESS = "LESSON_UPDATE_SUCCESS";

export const FETCH_LESSON = "FETCH_LESSON";
export const FETCH_LESSON_FAILURE = "FETCH_LESSON_FAILURE";
export const FETCH_LESSON_SUCCESS = "FETCH_LESSON_SUCCESS";
