import * as actionType from "./textContentActionType";

const initialState = {
  isLoading: false,
  isSaved: false,
  texts: []
};

export const textContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADD_TEXT_CONTENT:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.ADD_TEXT_CONTENT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.ADD_TEXT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.TEXT_CONTENT_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.TEXT_CONTENT_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.TEXT_CONTENT_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.FETCH_TEXT_CONTENT:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_TEXT_CONTENT_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_TEXT_CONTENT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                texts: action.payload ? action.payload.data : [],
            };
        default:
            return state;
    }
}