import { ClassPage } from './class.component';
import { connect } from 'react-redux';
import { registerClass, fetchClass, classUpdate } from './../../api/class/classAction';
 
const mapDispatchToProps = {
    registerClass,
    fetchClass,
    classUpdate
};

const mapStateToProps = state => ({
    isLoading: state.classPage.isLoading,
    isSaved: state.classPage.isSaved,
    classes: state.classPage.classes
});

export const ClassContainer = connect(mapStateToProps, mapDispatchToProps)(ClassPage);