import * as actionType from "./adminActionType";

const initialState = {
  isLoading: false,
  isLoggedIn: sessionStorage.getItem('loggedAdmin') !== null ? true : false,
  loggedAdmin: sessionStorage.getItem('loggedAdmin') !== null ? JSON.parse(sessionStorage.getItem('loggedAdmin')) : {}
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.ADMIN_LOGIN:
            return {
                ...state,
                isLoading: true,
                isLoggedIn: false
            };
        case actionType.ADMIN_LOGIN_FAILURE:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: false
            };
        case actionType.ADMIN_LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isLoggedIn: true,
                loggedAdmin: action.payload ? action.payload.data : {},
            };
        default:
            return state;
    }
}