import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';
import { MenuItem } from '@mui/material';

const RightDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        margin: '0',
        width: '500px',
        transform: 'translateX(-50%)',
        left: '50%',
        // [theme.breakpoints.up('sm')]: {
        //   maxWidth: '600px',
        // },
      },
  }));

export default function VideoDialog(props) {
  const { open, onClose, register, handleSubmit, errors, addVideoContent, watch, control, Controller, classes, getValues, fetchSubject, subjects, lessons, fetchLesson } = props;
  return (
    <RightDialog open={open} onClose={() => onClose} fullScreen>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3 }}>
            <DialogTitle>{watch('id') ? 'Update' : 'Create'} video</DialogTitle>
            <IconButton onClick={() => onClose()}><CancelIcon /></IconButton>
        </Box>
        <form onSubmit={handleSubmit(addVideoContent)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <Controller
                            name="ClassId"
                            control={control}
                            rules={{
                                required: 'Please select a class'
                            }}
                            render={({ field }) => (
                                <TextField
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        fetchSubject(e.target.value);
                                    }}
                                    fullWidth
                                    label="Class"
                                    variant="outlined"
                                    defaultValue={getValues("ClassId")}
                                    select
                                    error={!!errors.ClassId}
                                    helperText={errors.name && 'Please select a class'}
                                >
                                    {Array.isArray(classes) && classes.map(x => {
                                        return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                                    })}   
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item sm={12}>
                    <Controller
                            name="SubjectId"
                            control={control}
                            rules={{
                                required: 'Please select a subject'
                            }}
                            render={({ field }) => (
                                <TextField
                                    onChange={(e) => {
                                        field.onChange(e.target.value);
                                        fetchLesson(e.target.value);
                                    }}
                                    fullWidth
                                    label="Subject"
                                    variant="outlined"
                                    select
                                    defaultValue={getValues("SubjectId")}
                                    error={!!errors.SubjectId}
                                    helperText={errors.name && 'Please select a subject'}
                                >
                                    {Array.isArray(subjects) && subjects.map(x => {
                                return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                            })}  
                                </TextField>
                            )}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Lesson"
                            name="LessonId"
                            variant="outlined"
                            select
                            {...register('LessonId', { required: true })}
                            defaultValue={getValues("LessonId")}
                            error={!!errors.LessonId}
                            helperText={errors.name && 'Please select a lesson'}
                        >
                            {Array.isArray(lessons) && lessons.map(x => {
                                return <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem>
                            })}   
                        </TextField>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            variant="outlined"
                            {...register('name', { required: true })}
                            error={!!errors.name}
                            helperText={errors.name && 'Please enter a valid name'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Type"
                            name="type"
                            variant="outlined"
                            {...register('type', { required: true })}
                            error={!!errors.type}
                            defaultValue={"VDOCypher"}
                            helperText={errors.type && 'Please enter a valid type'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Video ID"
                            name="link"
                            variant="outlined"
                            {...register('link', { required: true })}
                            error={!!errors.link}
                            helperText={errors.link && 'Please enter a valid video ID'}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type='Submit'>{watch('id') ? 'Update' : 'Create' }</Button>
            </DialogActions>
        </form>
    </RightDialog>
  );
}