import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import CancelIcon from '@mui/icons-material/Cancel';

const RightDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        margin: '0',
        width: '500px',
        transform: 'translateX(-50%)',
        left: '50%',
        // [theme.breakpoints.up('sm')]: {
        //   maxWidth: '600px',
        // },
      },
  }));

export default function ClassDialog(props) {
  const { open, onClose, register, handleSubmit, errors, onSubmit, watch, control, Controller, onImageChange, image } = props;
  return (
    <RightDialog open={open} onClose={() => onClose} fullScreen>
        <Box sx={{ display: "flex", justifyContent: "space-between", px: 3 }}>
            <DialogTitle>{watch('id') ? 'Update' : 'Create'} Class</DialogTitle>
            <IconButton onClick={() => onClose()}><CancelIcon /></IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="Name"
                            name="name"
                            variant="outlined"
                            {...register('name', { required: true })}
                            error={!!errors.name}
                            helperText={errors.name && 'Please enter a valid name'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            fullWidth
                            label="ID"
                            name="_id"
                            variant="outlined"
                            {...register('_id', { required: true, pattern: {
                                value: /^\d{2}$/,
                                message: 'Please enter a valid id.',
                              } })}
                            error={!!errors._id}
                            helperText={errors._id && 'Please enter a valid id'}
                        />
                    </Grid>
                    <Grid item sm={12}>
                        <Controller
                            name="image"
                            control={control}
                            render={({ field }) => (
                                <input
                                type="file"
                                onChange={(e) => field.onChange(e.target.files[0])}
                                />
                            )}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()}>Cancel</Button>
                <Button type='Submit'>{watch('id') ? 'Update' : 'Create' }</Button>
            </DialogActions>
        </form>
    </RightDialog>
  );
}