import * as actionTypes from "./lessonActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
const { REACT_APP_API_URL } = process.env;

export const addLesson = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
        const params = new FormData();
            Object.keys(data).forEach(key => {
            const value = data[key];
            params.append(key, value);
        });
        dispatch(addLessonInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/lesson/store`, params, config))
        .then((response) => {
            dispatch(addLessonSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(addLessonFailure());
            checkError(error);
        })
    };
};

export const addLessonInit = () => ({
    type: actionTypes.ADD_LESSON
});

export const addLessonFailure = () => ({
    type: actionTypes.ADD_LESSON_FAILURE
});

export const addLessonSuccess = data => ({
    type: actionTypes.ADD_LESSON_SUCCESS
});

export const lessonUpdate = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
        const params = new FormData();
            Object.keys(data).forEach(key => {
            const value = data[key];
            params.append(key, value);
        });
        dispatch(lessonUpdateInit());
        apiTimeout(axios.put(`${REACT_APP_API_URL}/lesson/${data.id}`, params, config))
        .then((response) => {
            dispatch(lessonUpdateSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(lessonUpdateFailure());
            checkError(error);
        })
    };
};

export const lessonUpdateInit = () => ({
    type: actionTypes.LESSON_UPDATE
});

export const lessonUpdateFailure = () => ({
    type: actionTypes.LESSON_UPDATE_FAILURE
});

export const lessonUpdateSuccess = data => ({
    type: actionTypes.LESSON_UPDATE_SUCCESS
});

export const fetchLesson = (subjectId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
    dispatch(fetchLessonInit());
    apiTimeout(axios.get(`${REACT_APP_API_URL}/lesson?id=${subjectId}`, config))
    .then((response) => {
        dispatch(fetchLessonSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(fetchLessonFailure());
        checkError(error);
    })
    };
};

export const fetchLessonInit = () => ({
    type: actionTypes.FETCH_LESSON
});

export const fetchLessonFailure = () => ({
    type: actionTypes.FETCH_LESSON_FAILURE
});

export const fetchLessonSuccess = data => ({
    type: actionTypes.FETCH_LESSON_SUCCESS,
    payload: { data }
});