import react, { useState, useEffect } from 'react';
import { 
  Box,
  Typography,
  Button,
  MenuItem,
  TextField
} from '@mui/material';
import TablePage from './component/table.component';
import SubjectDialog from './component/subjectDialog.component';
import { useForm, Controller } from 'react-hook-form';

export function SubjectPage(props) {
  const { 
    addSubject,
    fetchSubject,
    subjectUpdate,
    isLoading,
    isSaved,
    subjects,
    fetchClass,
    classes
  } = props;

  const { register, handleSubmit, control, setValue, watch, reset, getValues, formState: { errors } } = useForm();
  const [open, setOpen] = useState(false);
  const [_class, setClass] = useState(1);

  useEffect(() => {
    if(classes.length === 0) {
      fetchClass();
    }
    fetchSubject(1);
  }, []);

  useEffect(() => {
    if(isSaved) {
      fetchSubject(_class);
      setOpen(false);
      reset();
    }
  }, [isSaved]);

  const setRowValues = (data) => {
    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  }

  const onSubmit = (data) => {
    if(data.id){
      subjectUpdate(data);
    } else {
      addSubject(data);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h1">Subject</Typography>
        <Box>
          <TextField
            variant="outlined"
            select
            label="Select Class"
            sx={{ width: "200px", mr: 2 }}
            size="small"
            value={_class}
            onChange={e => {
              setClass(e.target.value);
              fetchSubject(e.target.value);
            }}
          >
            {classes.map(x => {
              return (<MenuItem value={x.id} key={x.id}>{x.name}</MenuItem>)
            })}
          </TextField>
          <Button onClick={() => setOpen(true)} variant='contained'>Create Subject</Button>
        </Box>
      </Box>
      <TablePage
        rows={subjects}
        setOpen={setOpen}
        subjectUpdate={subjectUpdate}
        setRowValues={setRowValues}
      />
      <SubjectDialog
        open={open}
        onClose={() => {
          setOpen(false);
          reset();
        }}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        watch={watch}
        control={control}
        Controller={Controller}
        classes={classes}
        getValues={getValues}
      />
    </Box>
  );
}