import * as actionTypes from "./subjectActionType";
import axios from 'axios';
import { checkError, apiTimeout } from './../error';
const { REACT_APP_API_URL } = process.env;

export const addSubject = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
        const params = new FormData();
            Object.keys(data).forEach(key => {
            const value = data[key];
            params.append(key, value);
        });
        dispatch(addSubjectInit());
        apiTimeout(axios.post(`${REACT_APP_API_URL}/subject/store`, params, config))
        .then((response) => {
            dispatch(addSubjectSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(addSubjectFailure());
            checkError(error);
        })
    };
};

export const addSubjectInit = () => ({
    type: actionTypes.ADD_SUBJECT
});

export const addSubjectFailure = () => ({
    type: actionTypes.ADD_SUBJECT_FAILURE
});

export const addSubjectSuccess = data => ({
    type: actionTypes.ADD_SUBJECT_SUCCESS
});

export const subjectUpdate = (data) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
        const params = new FormData();
            Object.keys(data).forEach(key => {
            const value = data[key];
            params.append(key, value);
        });
        dispatch(subjectUpdateInit());
        apiTimeout(axios.put(`${REACT_APP_API_URL}/subject/${data.id}`, params, config))
        .then((response) => {
            dispatch(subjectUpdateSuccess(response.data.data));
        })
        .catch(function (error) {
            dispatch(subjectUpdateFailure());
            checkError(error);
        })
    };
};

export const subjectUpdateInit = () => ({
    type: actionTypes.SUBJECT_UPDATE
});

export const subjectUpdateFailure = () => ({
    type: actionTypes.SUBJECT_UPDATE_FAILURE
});

export const subjectUpdateSuccess = data => ({
    type: actionTypes.SUBJECT_UPDATE_SUCCESS
});

export const fetchSubject = (classId) => {
    const config = { headers: { Authorization: `Bearer ${sessionStorage.getItem("adminToken")}` } };
    return (dispatch) => {
    dispatch(fetchSubjectInit());
    apiTimeout(axios.get(`${REACT_APP_API_URL}/subject?classId=${classId}`, config))
    .then((response) => {
        dispatch(fetchSubjectSuccess(response.data.data));
    })
    .catch(function (error) {
        dispatch(fetchSubjectFailure());
        checkError(error);
    })
    };
};

export const fetchSubjectInit = () => ({
    type: actionTypes.FETCH_SUBJECT
});

export const fetchSubjectFailure = () => ({
    type: actionTypes.FETCH_SUBJECT_FAILURE
});

export const fetchSubjectSuccess = data => ({
    type: actionTypes.FETCH_SUBJECT_SUCCESS,
    payload: { data }
});