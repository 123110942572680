export const ADD_VIDEO_CONTENT = "ADD_VIDEO_CONTENT";
export const ADD_VIDEO_CONTENT_FAILURE = "ADD_VIDEO_CONTENT_FAILURE";
export const ADD_VIDEO_CONTENT_SUCCESS = "ADD_VIDEO_CONTENT_SUCCESS";

export const VIDEO_CONTENT_UPDATE = "VIDEO_CONTENT_UPDATE";
export const VIDEO_CONTENT_UPDATE_FAILURE = "VIDEO_CONTENT_UPDATE_FAILURE";
export const VIDEO_CONTENT_UPDATE_SUCCESS = "VIDEO_CONTENT_UPDATE_SUCCESS";

export const FETCH_VIDEO_CONTENT = "FETCH_VIDEO_CONTENT";
export const FETCH_VIDEO_CONTENT_FAILURE = "FETCH_VIDEO_CONTENT_FAILURE";
export const FETCH_VIDEO_CONTENT_SUCCESS = "FETCH_VIDEO_CONTENT_SUCCESS";
