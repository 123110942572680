import { LessonPage } from './lesson.component';
import { connect } from 'react-redux';
import { addLesson, fetchLesson, lessonUpdate } from './../../api/lesson/lessonAction';
import { fetchClass } from './../../api/class/classAction';
import { fetchSubject } from './../../api/subject/subjectAction';
import { addVideoContent } from './../../api/videoContent/videoContentAction';
 
const mapDispatchToProps = {
    addLesson,
    fetchLesson,
    lessonUpdate,
    fetchClass,
    fetchSubject,
    addVideoContent
};

const mapStateToProps = state => ({
    isLoading: state.lessonPage.isLoading,
    isSaved: state.lessonPage.isSaved,
    videoIsSaved: state.videoContentPage.isSaved,
    lessons: state.lessonPage.lessons,
    classes: state.classPage.classes,
    subjects: state.subjectPage.subjects,
});

export const LessonContainer = connect(mapStateToProps, mapDispatchToProps)(LessonPage);