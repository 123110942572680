import * as actionType from "./classActionType";

const initialState = {
  isLoading: false,
  isSaved: false,
  classes: []
};

export const classReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionType.REGISTER_CLASS:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.REGISTER_CLASS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.REGISTER_CLASS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.CLASS_UPDATE:
            return {
                ...state,
                isLoading: true,
                isSaved: false
            };
        case actionType.CLASS_UPDATE_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.CLASS_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isSaved: true
            };
        case actionType.FETCH_CLASS:
            return {
                ...state,
                isLoading: true
            };
        case actionType.FETCH_CLASS_FAILURE:
            return {
                ...state,
                isLoading: false
            };
        case actionType.FETCH_CLASS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                classes: action.payload ? action.payload.data : [],
            };
        default:
            return state;
    }
}