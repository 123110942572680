export const REGISTER_CLASS = "REGISTER_CLASS";
export const REGISTER_CLASS_FAILURE = "REGISTER_CLASS_FAILURE";
export const REGISTER_CLASS_SUCCESS = "REGISTER_CLASS_SUCCESS";

export const CLASS_UPDATE = "CLASS_UPDATE";
export const CLASS_UPDATE_FAILURE = "CLASS_UPDATE_FAILURE";
export const CLASS_UPDATE_SUCCESS = "CLASS_UPDATE_SUCCESS";

export const FETCH_CLASS = "FETCH_CLASS";
export const FETCH_CLASS_FAILURE = "FETCH_CLASS_FAILURE";
export const FETCH_CLASS_SUCCESS = "FETCH_CLASS_SUCCESS";
