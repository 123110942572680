import { LoginPage } from './login.component';
import { connect } from 'react-redux';
import { adminLogin } from './../../api/admin/adminAction';

const mapDispatchToProps = {
    adminLogin
};

const mapStateToProps = state => ({
    isLoading: state.adminPage.isLoading,
    isLoggedIn: state.adminPage.isLoggedIn,
    loggedAdmin: state.adminPage.loggedAdmin
});

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginPage);